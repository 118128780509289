import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Hidden, Paper } from '@material-ui/core';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image
import Homebg from "../images/homebg.jpg"

import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


  const Bline = styled.hr`
  width: 70px;
 border-bottom:2px solid #1c6a9c;
 margin-bottom:15px;
 margin-top:15px;
  `

  const Homeback = {
    backgroundAttachment: 'fixed',
    backgroundImage: 'url('+ Homebg+')'
    }


const Services = () => (
  <Layout>
    <Seo title="Implants - Orthopedic - Dialysis" description="Safe and reliable repairs - Quick and accurate evaluations - Savings up to 95%

" />
    <ThemeProvider theme={theme}>
    <Aboutback title="Services" />

   <div style={Homeback}>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>
     <Grid container justify="center">
       <Grid item xs={12} sm={12} md={7} align="justify">
       <Typography  gutterBottom variant="h4" component="h2" style={{ marginBottom:0 }}>
       Repairs
       </Typography>
       <Bline style={{ display:'inline-block' }}/>
       <List>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Safe and reliable repairs</Typography></ListItem>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Quick and accurate evaluations</Typography> </ListItem>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Savings up to 95%</Typography></ListItem>
       </List>
       <Typography  gutterBottom variant="h4" component="h2" style={{ marginBottom:0 }}>
Replacements
</Typography>
<Bline style={{ display:'inline-block' }}/>
<Typography  gutterBottom variant="body1">
Inventory ready for quick shipments to maintain equipment up-time
Technical Support
</Typography>
       <List>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Free Remote Assistance</Typography></ListItem>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Free step by step remote assistance for diagnosis and installation</Typography> </ListItem>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Avg diagnosis in 3 min</Typography> </ListItem>
           <ListItem><Typography variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Responsive support to lower total cost of ownership</Typography> </ListItem>
       </List>
       <Typography  gutterBottom variant="h4" component="h2" style={{ marginBottom:0 }}>
Support Capabilities
</Typography>
<Bline style={{ display:'inline-block' }}/>
<Typography  gutterBottom variant="body1">
Maintenance,Refurbishment and Repair of medical diagnostic equipment.
</Typography>

<Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>
    
       </Grid>

       <Grid item xs={12} sm={6} md={5}>
         <Box pr={3} pb={3}>
         <Paper elevation={3}>
         <StaticImage
      src="../images/services.jpg"
      alt="Africorp services"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
         </Paper>
         </Box>
         </Grid>

     </Grid>
     </Box>
   </Container>
   </div>
   </div>


    </ThemeProvider>
  </Layout>
)

export default Services
